.oc-access {
  &--icon-text {
    font-size: 0.75rem;
  }

  &--grid {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    gap: 0 1rem;
  }
}

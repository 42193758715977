:root {
  --oc-media-viewer-toolbar-height: 64px;
  --oc-media-viewer-toolbar-padding: 8px;
  --oc-media-viewer-button-size: 48px;
  --oc-media-viewer-button-padding: 12px;
}

@keyframes lightbox-toolbar-fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.yarl {
  &__root {
    --yarl__toolbar_padding: 0;
    --yarl__color_button: #fff;
    --yarl__button_background_color: transparent;
    --yarl__navigation_button_padding: 0;
  }

  &__button {
    position: relative;
    filter: unset;
    width: var(--oc-media-viewer-button-size);
    height: var(--oc-media-viewer-button-size);
    padding: var(--oc-media-viewer-button-padding);
  }

  &__toolbar {
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
    padding: var(--oc-media-viewer-toolbar-padding);

    @media (hover: hover) {
      // opacity: 0;

      // .yarl__root:hover & {
      //   animation: lightbox-toolbar-fadein 250ms linear forwards, lightbox-toolbar-fadein 250ms linear reverse forwards 4s;
      // }

      &:hover {
        opacity: 1 !important;
      }

      .yarl__button {
        &:after {
          content: '';
          transition: opacity 0.15s linear;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          opacity: 0;
          pointer-events: none;
          background: #fff;
          z-index: 0;
        }

        &:not(:disabled):hover {
          &::after {
            opacity: 0.1;
          }
        }
      }
    }
  }

  &__navigation {
    &_next,
    &_prev {
      --yarl__button_background_color: rgba(66, 66, 66, 0.54);

      position: absolute;
      width: 56px;
      height: 56px;
      border-radius: 28px;

      &:disabled {
        display: none;
      }
    }

    &_prev {
      left: 28px;
    }

    &_next {
      right: 28px;
    }
  }
}

.psv {
  &-navbar {
    box-sizing: border-box;
    padding: var(--oc-media-viewer-toolbar-padding);
    height: var(--oc-media-viewer-toolbar-height);
    display: flex;
    align-items: center;
  }

  &-button {
    box-sizing: border-box;
    color: #fff;
    position: relative;
    width: var(--oc-media-viewer-button-size);
    height: var(--oc-media-viewer-button-size);
    padding: var(--oc-media-viewer-button-padding);

    .psv-button-svg {
      width: calc(var(--oc-media-viewer-button-size) - calc(var(--oc-media-viewer-button-padding) * 2));
    }
  }
}

.oc-media-viewer-wrapper {
  // height: 100%;
  // width: 100%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}

.oc-media-viewer-content {
  // height: 100%;
}

:root {
  --ctrl-group-margin-mobile: 16px;
}

.oc-map-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--oc-surface);
}

.mapboxgl-map {
  font-size: 12px;
  font-family: var(--oc-font-family);
}

.mapboxgl-ctrl-group {
  background-color: transparent;

  &:not(:empty) {
    box-shadow: none;
  }

  .plt-mobile .mapboxgl-ctrl-bottom-right & {
    margin: 0 var(--ctrl-group-margin-mobile) var(--ctrl-group-margin-mobile) 0;
  }

  button {
    box-shadow: var(--md-shadows-6);
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border: 0;

    &:not(:disabled) {
      background-color: var(--md-palette-grey-300);
    }
    &:not(:disabled):hover {
      background-color: var(--md-palette-background-paper);
    }

    &.mapboxgl-ctrl-geolocate {
      border-radius: 50vh;
      width: 50px;
      height: 50px;

      .mapboxgl-ctrl-icon {
        background-size: 55%;

        [data-mui-color-scheme='light'] & {
          background-image: url('../../images/map/crosshair.dark.svg');
        }

        [data-mui-color-scheme='dark'] & {
          background-image: url('../../images/map/crosshair.light.svg');
        }
      }

      &:disabled {
        display: none;
      }
    }

    .plt-mobile & {
    }
  }
}

.mapboxgl-ctrl:has(.mapboxgl-ctrl-logo) {
  display: none !important;
}

// Safari < 10.16
.mapboxgl-ctrl-logo {
  display: none !important;
}

.marker-icon-default {
  > :nth-child(2) {
    fill: #000;
  }
}

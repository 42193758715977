.oc-quick-actions {
}

.oc-quick-actions-lg {
  // outline: 10px solid red;
  .oc-quick-actions {
    &--btn {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      color: var(--ion-color-primary);
      font-size: 0.75rem;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), background-color 15ms linear, color 15ms linear;
      width: 100%;
      margin-left: 0;
      margin-right: 0;

      &:hover {
        .oc-quick-actions--btn-icon {
          background-color: rgba(var(--ion-color-primary-rgb), 0.04);
        }
      }

      &-icon {
        border: 0.666667px solid var(--ion-color-primary);
        margin: 6px;
        border-radius: 36px;
        width: 36px;
        height: 36px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }

      &.primary {
        .oc-quick-actions--btn-icon {
          background-color: var(--ion-color-primary);
          color: #fff;
        }

        &:hover {
          .oc-quick-actions--btn-icon {
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px rgba(0, 0, 0, 0.15);
          }
        }
      }

      &-label {
        text-align: center;
      }
    }
  }
}

.oc-quick-actions-sm {
  .oc-quick-actions {
    &--row {
      &:first-child {
        margin-left: 0;
      }
    }

    &--col {
      flex-grow: 0;
      padding-right: 10px;

      &:last-child {
        padding-right: 0;
      }
    }

    &--btn {
      // min-width: 80px;
      // width: 100%;
      // margin-inline: 0;
    }

    &--row:first {
      .oc-quick-actions--btn:first-child {
        margin-left: 0;
      }
    }
  }
}

@keyframes spinner-dots {
  0% {
    transform: scale(1, 1);

    opacity: 0.9;
  }

  50% {
    transform: scale(0.3, 0.3);

    opacity: 0.3;
  }

  100% {
    transform: scale(1, 1);

    opacity: 0.9;
  }
}

.oc-map-loading {
  --oc-map-loading-color: #bdb9ae;
  --oc-map-loading-spinner-size: 16.6667%;

  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--oc-surface);

  &--box {
    // outline: 1px dotted #ff01017b;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: calc(100% - 6rem);
  }

  h1 {
    font-family: Hero;
    color: #fff;
    font-size: 3rem;
    font-size: 12vmin;
    font-weight: 600;
    margin: 0;
    padding: 0;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;

    @media (min-width: 800px) {
      & {
        font-size: 6rem;
      }
    }
  }

  pre {
    max-width: 100%;
    padding-left: 1em !important;
    padding-right: 1em !important;
    padding-bottom: 1em;
  }

  &--logo {
    width: 35%;
    margin-bottom: 1rem;

    .path-1,
    .path-2 {
      opacity: 0.5;
    }

    .path-3 {
      opacity: 1;
    }
  }

  &--spinner {
    margin-top: 3rem;
    display: inline-block;
    position: relative;
    // width: 100px;
    width: var(--oc-map-loading-spinner-size);
    // height: 50px;
    aspect-ratio: 2;
  }

  &--spinner-dot {
    fill: var(--oc-map-loading-color);
    transform-origin: center center;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    transform: translateZ(0px);
    animation: 0.75s linear 0s infinite normal none running spinner-dots;

    &.spinner-dot-1 {
      left: 30%;
    }

    &.spinner-dot-3 {
      left: -30%;
    }
  }
}

.oc-result-pane-lg {
  max-width: 400px;
  // margin: 0;
  // border-radius: 0;
  // box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3), 0 2px 6px 2px rgba(60, 64, 67, 0.15);

  .oc-result-header {
    font-size: 1.375rem;
    font-weight: 400;
  }

  .oc-result-pane--header {
    // padding-inline: var(--oc-pane-padding-inline);
  }
}

.oc-search-bar {
  &--results {
    mark {
      background-color: transparent;
      color: inherit;
      font-weight: bold;

      [data-mui-color-scheme='dark'] & {
        font-weight: inherit;
        color: var(--md-palette-primary-contrastText);
      }
    }
  }
}

/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/* Custom additions */

// ionic
:root {
  --font-size: 16px;
  font-size: var(--font-size);
}

// end ionic

:root {
  --oc-titlebar-default-height: 33px;

  --oc-filter-menu-top: env(titlebar-area-height, 0);
  --oc-filter-menu-width: 500px;
  --oc-filter-menu-border-radius: 0.5rem 0 0 0.5rem;

  --oc-font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  --oc-radius: 8px;
  --oc-surface: #ebebeb;
  --oc-icon-color-secondary: #666666;
  --oc-text-color-secondary: #444;
  --oc-marker-selected-color: #7faef5;
  --oc-marker-selected-font-size: 1.1em;

  // z-indexes
  --oc-app-menu-z-index: 1;
  --oc-filter-menu-z-index: 10000;
  --oc-searchbar-z-index: calc(var(--md-zIndex-drawer) - 1);
  --oc-searchbar-sm-z-index: calc(var(--md-zIndex-drawer) - 1);
  --oc-result-pane-z-index: calc(var(--md-zIndex-drawer) - 2);
  --oc-result-pane-sm-z-index: calc(var(--md-zIndex-drawer) - 1);

  // font sizes
  --oc-map-text-primary-font-size: 1rem;
  --oc-map-text-secondary-font-size: 0.875rem;
  --oc-map-text-small-font-size: 0.75rem;

  --oc-pane-padding-inline: 22px;
  --oc-pane-padding-block: 16px;
}

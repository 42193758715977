@import url('fonts/hero/hero.scss');
@import url('utils/disable-pull-to-refresh.scss');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:not(.MuiButtonBase-root) {
  color: inherit;
  text-decoration-color: var(--md-palette-secondary-main);
  text-decoration-thickness: 2px;
  transition: all var(--md-transition-duration-shortest) ease-in-out;
}

a:not(.MuiButtonBase-root):hover {
  color: var(--md-palette-secondary-dark);
  text-decoration-color: var(--md-palette-secondary-dark);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

img {
  max-width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}

// Checkbox
ion-checkbox {
  --checkbox-background: transparent;
  --checkbox-background-checked: transparent;
  --border-width: 0;
}

ion-button {
  text-transform: none;

  .MuiSvgIcon-root {
    margin-left: -0.3em;
    margin-right: 0.3em;
    font-size: 1.4em;
  }
}

.toast {
  --max-width: 290px;

  @media (max-width: 767px) {
    --max-width: 100%;
    --start: 0;
    --end: 0;
    --border-radius: 0;
  }
}

.oc-progress-bar {
  z-index: 100;
}

.oc-dialog {
  // ionic
  .alert-message {
    color: #49454e;
    padding-bottom: 0;
  }

  // ionic
  .alert-button-group {
    padding: 16px 23px 20px;
  }
}

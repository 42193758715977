.oc-filter-coordinate-item {
  &--label {
    padding: 10px 0px;
    margin: 0;
  }
}

.oc-filter-accessibility-item {
  &--inner {
    padding: 6px 0;
  }

  &--checkbox {
    margin-bottom: 0;
  }

  &--note {
    display: block;
  }

  &--nb {
    margin-left: 0.75rem;
    font-size: 75%;
  }
}

.oc-result-pane {
  --oc-results-sistemas--item-padding: 1em;
  --oc-details-icon-min-width: 40px;

  &--content {
    .h2 {
      font-size: 1rem;
      font-weight: 500;
      margin: 10px var(--oc-pane-padding-inline) 10px var(--oc-pane-padding-inline);
      margin: 0;
    }

    .details-container {
      padding: var(--oc-pane-padding-block) var(--oc-pane-padding-inline);

      & + .details-container {
        padding-top: 0;
      }
    }

    .details-text {
      > *:first-child {
        margin-top: 0;
      }

      > *:last-child {
        margin-bottom: 0;
      }

      p {
        font-size: inherit;
        margin-bottom: 1em;
      }

      ul,
      ol {
        padding-left: 1em;
      }
    }

    // ion-item {
    //   --min-height: 40px;
    //   --padding-start: var(--oc-pane-padding-inline);

    //   font-size: inherit;
    //   line-height: 1.25rem;

    //   > svg[slot='start'] {
    //     margin-right: 24px;
    //   }
    // }

    .oc-results-copy-list {
      padding-top: var(--oc-pane-padding-block);
      padding-bottom: var(--oc-pane-padding-block);

      .MuiListItemButton-root {
        padding: 6px var(--oc-pane-padding-inline);

        .oc-result-pane:not(.oc-result-pane-sm) & {
          .oc-icon-copy {
            display: none;
          }

          &:hover {
            .oc-icon-copy {
              display: inline-block;
            }
          }
        }
      }

      .MuiListItemIcon-root {
        min-width: var(--oc-details-icon-min-width);
      }

      .oc-icon-copy-container {
        justify-content: flex-end;
        min-width: 18px;
      }
    }
  }

  .oc-results-sistemas {
    --inner-border-width: 0;

    padding: 8px 0;

    &--sistemas {
      --inner-border-width: 0;
      --padding-top: 16px;
      --padding-bottom: 16px;

      // background-color: hsl(0 0% 95% / 1);
      // padding-inline-start: var(--oc-pane-padding-inline);
    }

    &--item {
      // outline: 1px solid red;
      margin-bottom: 0.5em;

      &:last-child {
        margin-bottom: 0;
      }

      &-note {
        font-size: 12px;
      }
    }
  }
}

.oc-result-pane-sm {
  --oc-result-pane-z-index: var(--oc-result-pane-sm-z-index);
  --oc-result-pane-sm-upper-height: 48px;
  --oc-result-pane-border-radius: 1rem 1rem 0 0;

  --height: calc(100% + var(--oc-pane-padding-block)); // ion-modal
  --border-radius: var(--oc-result-pane-border-radius); // ion-modal20.27736, -87.43314
  --background: var(--md-palette-background-paper); // ion-modal

  top: var(--oc-result-pane-sm-upper-height);

  // ion-modal
  &::part(backdrop) {
    display: none;
  }

  &::part(handle) {
    width: 54px;
  }

  .oc-back-btn {
    position: absolute;
    top: 4px;
    left: 8px;
  }

  .oc-result-pane--card {
    box-shadow: none;
    margin: 0;
    padding-top: 0;
    transition: 0.125s padding-top;
  }

  .oc-result-pane--header {
    padding-left: var(--oc-pane-padding-inline);
    padding-right: var(--oc-pane-padding-inline);
    padding-bottom: 0;
  }
}

.oc-result-pane--head {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 49px;
  background-color: var(--md-palette-background-paper);
  padding-left: calc(var(--oc-pane-padding-inline) - 5px);
  padding-right: calc(var(--oc-pane-padding-inline) - 5px);
  z-index: var(--oc-result-pane-sm-z-index);
  opacity: 0;
}
